/* eslint-disable @typescript-eslint/no-floating-promises */
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {errorInCheckoutSfParams} from '@wix/bi-logger-ec-sf/v2/types';
import {PaymentMethod} from '@wix/wixstores-graphql-schema-node';
import {ContinueShoppingBiOrigin, EMPTY_CART_ID, PaymentMethodType} from '../../common/constants';
import {CartType, ShippingRuleStatus} from '@wix/wixstores-client-core';
import {ICart} from '../../types/app.types';
import {
  getAdditionalFeesPrice,
  getCatalogAppIds,
  getItemsCount,
  getItemTypes,
  getNumberOfAdditionalFees,
  getOriginalShippingMethod,
  getShippingMethodType,
  getValidations,
  stringifyPaymentMethods,
  toBiAmount,
} from '../utils/bi.utils';
import {clickOnApplyPromotionalCodeSf, errorInCheckoutSf, errorWhenApplyingACouponSf} from '@wix/bi-logger-ec-sf/v2';
import {
  cancelClickedInSelectYourShippingDestinationPopupSf as cancelClickedInSelectYourShippingDestinationPopupSfSite,
  clickOnAddNoteToSellerSf as clickOnAddNoteToSellerSfSite,
  clickOnCheckoutWithEWallet as clickOnCheckoutWithEWalletSite,
  clickOnShippingDestinationInCartPageSf as clickOnShippingDestinationInCartPageSfSite,
  deleteNoteToSellerSf as deleteNoteToSellerSfSite,
  saveNoteToSellerSf as saveNoteToSellerSfSite,
  updateClickedInSelectYourShippingDestinationPopupSf as updateClickedInSelectYourShippingDestinationPopupSfSite,
  viewCartPageSf as viewCartPageSfSite,
} from '@wix/bi-logger-ec-site/v2';
import {
  cartSideCartErrorPresentedInCartSideCartSrc130Evid131,
  clickToAddCoupon,
  errorInCheckout,
  errorWhenApplyingACoupon,
  cancelClickedInSelectYourShippingDestinationPopupSf,
  clickOnAddNoteToSellerSf,
  clickOnCheckoutWithEWallet,
  clickOnShippingDestinationInCartPageSf,
  deleteNoteToSellerSf,
  saveNoteToSellerSf,
  updateClickedInSelectYourShippingDestinationPopupSf,
  viewCartPageSf,
  clickOnContinueShoppingFromCart,
  cartMinimumOrderMessageIsShownInCart,
} from '@wix/bi-logger-ecom-platform-data/v2';
import {SPECS} from '../specs';

type SetDestinationBIData = {
  cartId: string;
  cartType: string;
  shippingDestinationCountry?: string;
  shippingDestinationState?: string;
};

export class BIService {
  private readonly siteStore: SiteStore;
  private readonly origin: string;

  constructor({siteStore, origin}: {siteStore: SiteStore; origin: string}) {
    this.siteStore = siteStore;
    this.origin = origin;
  }

  private baseBiParams(cart: ICart): {origin: string; cartId?: string; storeId?: string} {
    return {
      origin: this.origin,
      cartId: !cart || cart.cartId === EMPTY_CART_ID ? undefined : cart.cartId,
      storeId: cart?.storeId,
    };
  }

  public clickOnShippingDestinationInCartPageSf(cart: ICart, cartType: string): void {
    const biParams = {
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          clickOnShippingDestinationInCartPageSf({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
            ecomId: cart.ecomId,
          })
        )
      : this.siteStore.webBiLogger.report(
          clickOnShippingDestinationInCartPageSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
            ecomId: cart.ecomId,
          })
        );
  }

  public clickOnCheckoutWithEWallet(
    cart: ICart,
    cartType: string,
    /* istanbul ignore next */ paymentMethods: PaymentMethod[] = [],
    isWithOfflinePayment: boolean,
    isPickupFlow: boolean
  ): void {
    const params = {
      ...this.baseBiParams(cart),
      ...BIService.setDestinationBIData(cart, cartType),
      cartType,
      itemsCount: getItemsCount(cart),
      productsList: JSON.stringify(cart.items.map((item) => ({id: item.product.id, quantity: item.quantity}))),
      shippingMethodType: getShippingMethodType(isPickupFlow, cartType !== CartType.PHYSICAL),
      orig_shipping_method: getOriginalShippingMethod(cart),
      is_with_ewallet_payment: true,
      is_with_offline_payment: isWithOfflinePayment,
      num_of_paymet_providers: paymentMethods.length,
      paymet_providers: stringifyPaymentMethods(paymentMethods),
      num_of_shipping: cart.shippingRuleInfo?.shippingRule?.options?.length ?? /* istanbul ignore next */ 0,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
      checkoutId: cart?.checkoutId,
      ecomId: cart?.ecomId,
      catalogAppId: getCatalogAppIds(cart),
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          clickOnCheckoutWithEWallet({
            ...params,
          })
        )
      : this.siteStore.webBiLogger.report(
          clickOnCheckoutWithEWalletSite({
            ...params,
          })
        );
  }

  public updateClickedInSelectYourShippingDestinationPopupSf(
    cart: ICart,
    cartType: string,
    changedDestination: {country: string; subdivision: string; zipCode: string}
  ): void {
    const biParams = {
      ...this.baseBiParams(cart),
      shippingDestinationCountry: changedDestination.country,
      shippingDestinationState: changedDestination.subdivision,
      hasZipCode: !!changedDestination.zipCode,
      cartId: cart.cartId,
      cartType,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          updateClickedInSelectYourShippingDestinationPopupSf({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(
          updateClickedInSelectYourShippingDestinationPopupSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart.checkoutId,
          })
        );
  }

  public errorInCheckoutSf(cart: ICart): void {
    const {destination} = cart;
    const subdivision = destination.subdivision ? ` - ${destination.subdivision}` : /* istanbul ignore next */ '';
    const destinationStr = `${destination.country}${subdivision}`;
    const params: errorInCheckoutSfParams = {
      ...this.baseBiParams(cart),
      field: 'destination',
      message: `cannot ship to destination ${destinationStr}`,
      stage: 'cart',
    };
    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(errorInCheckout(params))
      : this.siteStore.webBiLogger.report(errorInCheckoutSf(params));
  }

  public cancelClickedInSelectYourShippingDestinationPopupSf(cart: ICart, cartType: CartType): void {
    const biParams = {
      ...this.baseBiParams(cart),
      cartType,
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          cancelClickedInSelectYourShippingDestinationPopupSf({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(
          cancelClickedInSelectYourShippingDestinationPopupSfSite({
            ...biParams,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        );
  }

  public cartClickOnContinueShoppingSf(cart: ICart, origin: ContinueShoppingBiOrigin): void {
    const biParams = {
      ...this.baseBiParams(cart),
      origin,
      catalogAppId: getCatalogAppIds(cart),
      checkoutId: cart?.checkoutId,
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      itemType: getItemTypes(cart),
    };
    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(clickOnContinueShoppingFromCart(biParams))
      : this.siteStore.platformBiLogger.clickOnContinueShoppingFromCart(biParams);
  }

  public clickToViewCartPage(cart: ICart, cartType: string): void {
    const biParams = {
      ...this.baseBiParams(cart),
      origin: this.origin,
      purchaseFlowId: cart.purchaseFlowId,
      itemsCount: getItemsCount(cart),
      cartType,
      catalogAppId: getCatalogAppIds(cart),
      checkoutId: cart?.checkoutId,
      isNavigateCart: true,
    };

    this.siteStore.platformBiLogger.clickToViewCartPage(biParams);
  }

  public minimumOrderMessageIsShownInCart(cart: ICart, buttonEnabled: boolean): void {
    const biParams = {
      ...this.baseBiParams(cart),
      currency: cart.currencyFormat.code,
      minimumValueLong: toBiAmount(cart.minimumOrderAmount.convertedValue),
      diffLong: toBiAmount(cart.minimumOrderAmount.convertedDiff),
      buttonEnabled,
      checkoutId: cart?.checkoutId,
      ecomId: cart?.ecomId,
      catalogAppId: getCatalogAppIds(cart),
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(cartMinimumOrderMessageIsShownInCart(biParams))
      : this.siteStore.platformBiLogger.cartMinimumOrderMessageIsShownInCart(biParams);
  }

  public clickOnAddNoteToSellerSf(cart: ICart): void {
    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          clickOnAddNoteToSellerSf({
            ...this.baseBiParams(cart),
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        )
      : this.siteStore.webBiLogger.report(
          clickOnAddNoteToSellerSfSite({
            ...this.baseBiParams(cart),
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
          })
        );
  }

  public clickOnApplyPromotionalCodeSf(cart: ICart): void {
    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          clickToAddCoupon({
            ...this.baseBiParams(cart),
          })
        )
      : this.siteStore.webBiLogger.report(
          clickOnApplyPromotionalCodeSf({
            ...this.baseBiParams(cart),
          })
        );
  }

  public errorWhenApplyingACouponSf(cart: ICart, couponCode: string, errorCode: string): void {
    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          errorWhenApplyingACoupon({
            ...this.baseBiParams(cart),
            couponCode,
            errorCode,
          })
        )
      : this.siteStore.webBiLogger.report(
          errorWhenApplyingACouponSf({
            ...this.baseBiParams(cart),
            couponCode,
            errorCode,
          })
        );
  }

  public errorPresentedInCartSideCart(errorMessage: string): void {
    this.siteStore.webBiLogger.report(
      cartSideCartErrorPresentedInCartSideCartSrc130Evid131({origin: this.origin, errorMessage})
    );
  }

  public updateBuyerNote = (cart: ICart, hasNote: boolean): void => {
    const biParams = {cartId: cart.cartId, origin: this.origin};
    const catalogAppId = getCatalogAppIds(cart);
    if (this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)) {
      hasNote
        ? this.siteStore.webBiLogger.report(
            saveNoteToSellerSf({
              ...biParams,
              is_empty: false,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          )
        : this.siteStore.webBiLogger.report(
            deleteNoteToSellerSf({
              ...biParams,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          );
    } else {
      hasNote
        ? this.siteStore.webBiLogger.report(
            saveNoteToSellerSfSite({
              ...biParams,
              is_empty: false,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          )
        : this.siteStore.webBiLogger.report(
            deleteNoteToSellerSfSite({
              ...biParams,
              catalogAppId,
              checkoutId: cart.checkoutId,
            })
          );
    }
  };

  public viewCartPageSf({
    cart,
    cartType,
    paymentMethods,
    numOfVisibleShippingOptions,
    shouldShowCoupon,
    shouldShowExpressCheckout,
    shouldShowBuyerNote,
    shouldShowContinueShopping,
    shouldShowShipping,
    shouldShowTax,
    hasPickupOption,
    cartLayout,
    isCheckoutButtonPresented,
    isViewCartButtonPresented,
  }: {
    cart: ICart;
    cartType: string;
    paymentMethods: PaymentMethod[];
    numOfVisibleShippingOptions: number;
    shouldShowCoupon: boolean;
    shouldShowBuyerNote: boolean;
    shouldShowExpressCheckout: boolean;
    shouldShowContinueShopping: boolean;
    shouldShowShipping: boolean;
    shouldShowTax: boolean;
    hasPickupOption: boolean;
    cartLayout: string;
    isCheckoutButtonPresented: boolean;
    isViewCartButtonPresented: boolean;
  }): void {
    const isShippingCalculated = cart?.shippingRuleInfo?.status === ShippingRuleStatus.OK;
    const isAddFieldsToViewCartPageBIEnabled = this.siteStore.experiments.enabled(SPECS.AddFieldsToViewCartPageBI);

    const biParams = {
      ...this.baseBiParams(cart),
      cartType,
      checkoutType: 'viewer',
      isContinueShopping: shouldShowContinueShopping,
      isDeliveryRates: shouldShowShipping,
      isPickupAvailable: hasPickupOption,
      isShippingCalculated,
      isTaxCosts: shouldShowTax,
      is_note: shouldShowBuyerNote,
      is_promo_code: shouldShowCoupon,
      ...(!isAddFieldsToViewCartPageBIEnabled && {
        is_express_checkout: shouldShowExpressCheckout,
      }),
      is_with_ewallet_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.eWallet),
      is_with_offline_payment: paymentMethods.map((m) => m.type).includes(PaymentMethodType.offline),
      itemsCount: getItemsCount(cart),
      lineItemsCount: cart?.items.length || 0,
      num_of_paymet_providers: paymentMethods.length,
      num_of_shipping: numOfVisibleShippingOptions,
      paymet_providers: paymentMethods.map((m) => m.name).join(','),
      shippingReason: isShippingCalculated ? '' : cart?.shippingRuleInfo?.status || 'unknown',
      viewMode: this.siteStore.viewMode,
      additionalFeesPrice: getAdditionalFeesPrice(cart),
      numberOfAdditionalFees: getNumberOfAdditionalFees(cart),
      savePaymentMethod: cart?.items?.some((item) => item.savePaymentMethod),
      ...(isAddFieldsToViewCartPageBIEnabled && {
        isCheckoutButtonPresented: cartLayout === 'sideCart' ? isCheckoutButtonPresented.toString() : 'true',
        isViewCartButtonPresented: cartLayout === 'sideCart' ? isViewCartButtonPresented.toString() : 'false',
      }),
    };

    this.siteStore.experiments.enabled(SPECS.shouldChangeToBiCatalogV2)
      ? this.siteStore.webBiLogger.report(
          viewCartPageSf({
            ...biParams,
            cartLayout,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
            ecomId: cart?.ecomId,
            purchaseFlowId: cart?.purchaseFlowId,
            is_member: !!this.siteStore.usersApi?.currentUser?.loggedIn,
            validationMessage: getValidations(
              cart?.violations,
              this.siteStore.experiments.enabled(SPECS.SupportDeliveryViolationOnCart)
            ),
          })
        )
      : this.siteStore.webBiLogger.report(
          viewCartPageSfSite({
            ...biParams,
            cartLayout,
            catalogAppId: getCatalogAppIds(cart),
            checkoutId: cart?.checkoutId,
            ecomId: cart?.ecomId,
            purchaseFlowId: cart?.purchaseFlowId,
            is_member: !!this.siteStore.usersApi?.currentUser?.loggedIn,
            validationMessage: getValidations(
              cart?.violations,
              this.siteStore.experiments.enabled(SPECS.SupportDeliveryViolationOnCart)
            ),
          })
        );
  }

  private static setDestinationBIData(cart: ICart, cartType: string) {
    const biData: SetDestinationBIData = {
      cartId: cart.cartId,
      cartType,
    };
    if (cart.destination) {
      biData.shippingDestinationCountry = cart.destination.country;
      biData.shippingDestinationState = cart.destination.subdivision;
    }
    return biData;
  }
}
